



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Dealer from '@/models/Dealer.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { namespace } from 'vuex-class';
import { DealerStoreActions } from '@/store/dealer.store';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const DealerStore = namespace('dealer');

@Component
export default class DealerListComponent extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public dealers!: Dealer[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ required: true })
  public startEditing!: (dealer: Dealer) => void;

  @Prop({ default: '' })
  public search!: string;

  @DealerStore.Action(DealerStoreActions.RESEND_SET_PASSWORD)
  private resendSetPasswordAction!: (dealerId: string) => Promise<any>;

  private headers = [
    { text: this.$t('DEALER.FIRST_NAME'), align: 'start', sortable: true, value: 'firstName' },
    { text: this.$t('DEALER.LAST_NAME'), align: 'start', sortable: true, value: 'lastName' },
    { text: this.$t('DEALER.EMAIL'), align: 'start', sortable: true, value: 'email' },
    { text: this.$t('DEALER.STORE'), align: 'start', sortable: true, value: 'store' },
    { text: this.$t('DEALER.VERIFIED'), align: 'center', sortable: false, value: 'isVerified' },
    { text: this.$t('DEALER.ACTIVE'), align: 'center', sortable: false, value: 'status' },
    { text: this.$t('DEALER.ROLE'), align: 'center', sortable: false, value: 'role' },
    { text: this.$t('GENERAL.ACTIONS'), align: 'start', sortable: false, value: 'actions' },
  ];

  private async resendSetPassword(dealerId: string) {
    try {
      await this.resendSetPasswordAction(dealerId);
      this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.RESEND_PASSWORD_MAIL_SENT');
    } catch (e) {
      this.handleAxiosError(e);
    }
  }
}
