var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items":_vm.dealers,"loading":_vm.isLoading,"loading-text":_vm.$t('GENERAL.LOADING_DATA'),"footer-props":{
    'items-per-page-options': [10, 25, 100],
    'items-per-page-text': _vm.$t('GENERAL.ROWS_PER_PAGE'),
    'page-text': ("{0}-{1} " + (_vm.$t('GENERAL.OF')) + " {2}")
  },"no-data-text":_vm.$t('GENERAL.NO_DATA'),"search":_vm.search},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.firstName))]),_c('td',[_vm._v(_vm._s(props.item.lastName))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[(props.item.store)?_c('span',[_vm._v(_vm._s(props.item.store.name))]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"text-center"},[(props.item.isVerified)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("fas fa-check")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("fas fa-times-circle")])],1),_c('td',{staticClass:"text-center"},[(props.item.status)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("fas fa-check")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("fas fa-times-circle")])],1),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.getRoleRepresentation(props.item.role).iconColor,"small":""}},on),[_vm._v(" "+_vm._s(_vm.getRoleRepresentation(props.item.role).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getRoleRepresentation(props.item.role).title))])])],1)]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.startEditing(props.item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"secondary"}},[_vm._v("fas fa-pen")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('DEALER.UPDATE_DEALER')))])]),(!props.item.isVerified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.resendSetPassword(props.item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-envelope")])],1)]}}],null,true)},[_c('span',{staticClass:"pre-white-space"},[_vm._v(_vm._s(_vm.$t('DEALER.RESEND_SET_PASSWORD_MAIL')))])]):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }